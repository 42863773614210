import React from "react";
import "./Pages.css";
import chat from "../../assets/chat.png";

const Anzeige = () => {
  return (
    <div className="anzeige">
      <img width={300} src={chat} alt="Anzeige" />
      <p>
        Sehr geehrte Patienten, <pre/>
        wir machen Urlaub vom 23.12.2024 - 03.01.2025.<pre/>
        Ab dem 06.01.2025 sind wir wie gewohnt ab 08:00 Uhr für Sie da.<pre/>
        In dringenden Notfällen wenden Sie sich bitte an das nächstgelegene Krankenhaus.<pre/>
        Wir wünschen frohe Feiertage und ein gesundes neues Jahr.<pre/>
        Ihr Uro Praxis Team.<pre/>
        Wir sind eine Facharztpraxis, da kann es schon durchaus zu etwas längeren<pre/>
        Wartezeiten kommen. Dafür bitten wir um Verständnis.<pre/>
        <pre/>
        Die Untersuchung bei Neupatienten ist immer etwas aufwändiger. Bitte planen
        Sie genügend Zeit ein.<pre/>
        <pre/>
        Wir haben mehrere Untersuchungs-/Behandlungsräume, deswegen kann es
        auch durchaus sein, dass ein Patient, der nach Ihnen kam, vor Ihnen aufgerufen
        wird.<pre/>
        <pre/>
        Viele Patienten kommen z.B. nur zur Blutabnahme, Katheterwechsel usw. und
        müssen an diesem Tag nicht zum Arzt.<pre/>
        Das sind Untersuchungen, die von den Fachangestellten durchgeführt
        werden.<pre/>
        <pre/>
        An manchen Tagen haben wir auch Notfälle. Dies sind Patienten mit z.B.
        Koliken und unter Schmerzen können diese Patienten nicht warten und
        brauchen zeitnah eine Versorgung.<pre/>
        <pre/>
        Wir bitten um Ihr Verständnis.<pre/>
        Ihr URO-Praxisteam<pre /><pre />
      </p>
    </div>
  );
};

export default Anzeige;
